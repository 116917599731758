import { createApp } from 'vue';

import App from './App.vue';
import router from './router';
import store from './store';

import './plugins/flexble';

import './assets/styles/main.less';
import 'vant/lib/index.css';
import "animate.css";

import vant from "./plugins/vant";

const app = createApp(App);
app.use(store);
app.use(router);
app.use(vant);

/** 获取参数，保存状态 */
import { getParamsFromUrl } from '@/utils/index';
import { useParams } from '@/store/useParams';
const { setParams } = useParams();
let params: AnyObject = getParamsFromUrl(window.location.href);
setParams(params);

import { Haa } from '@haa/fwk-h5/lib/index';
import { SPACE_MAP } from './utils/constant';
import '@haa/fwk-h5/lib/styles/index.css';
import WapInter from '@haa/fwk-h5/components/wap-inter.vue';
import WapInterNative from '@haa/fwk-h5/components/wap-inter-native.vue';
import WapNative from '@haa/fwk-h5/components/wap-native.vue';
import WapBanner from '@haa/fwk-h5/components/wap-banner.vue';
app.component('WapInter', WapInter);
app.component('WapInterNative', WapInterNative);
app.component('WapNative', WapNative);
app.component('WapBanner', WapBanner);
const cfg = {
    queryMap: {
        channel: 'channel',
        subchannel: 'subchannel',
        key: 'key',
    },
    spaceMap: SPACE_MAP,
    router: router,
    home: ['Home', 'Analysis', 'Answer', 'Topic'],
    app: app,
    deepStart: params.skip == '1' ? 2 : 1,
    firstInterSpaceKey: 'Home_inter',
};
Haa.init(cfg);

app.mount('#app');
