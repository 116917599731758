export const CATEGORY_MAP = {
    'Teeth': '牙齿治疗',
    'Bodycare': '美体塑性',
    'Skin': '皮肤美容',
    'Eye': '眼科美容',
    'Eyesight': '视力检查',
    'Drive': '驾驶培训',
    'English': '英语培训',
    'Study': '留学申请',
    'Fitness': '健身养生培训',
    'Fitment': '家庭装修',
    'Wellness': '养生知识',
    'Hair': '头发种植',
};

export const CategoryConfig = {
    'Teeth': {
        category: '牙齿治疗',
        banners: [
            { title: '来查查你的牙齿有什么问题？', btn: '立即报名' },
            { title: '本地牙齿种植价格表', btn: '立即查看' },
            { title: '口腔医院排行榜', btn: '登录查看' },
            { title: '免费连线专家医生问诊', btn: '立即报名 ' },
        ],
    },
    'Eyesight': {
        category: '视力检查',
        banners: [
            { title: '免费在线测视力', btn: '立即测试' },
            { title: '附近的眼科医院正在做活动', btn: '立即查看' },
            { title: '报名免费领取眼科医院优惠券', btn: '立即报名' },
        ],
    },
    'Hair': {
        category: '头发种植',
        banners: [
            { title: '植发医院排行榜', btn: '登录查看' },
            { title: '免费连线专家咨询植发价格', btn: '登录报名' },
            { title: '本地植发价格表', btn: '登录查看' },
        ],
    },
    'English': {
        category: '英语培训',
        banners: [
            { title: '本地英语学校排行榜', btn: '登录查看' },
            { title: '登录领取英语培训课程安排', btn: '登录领取' },
        ],
    },
    'lvyou': {
        category: '旅游',
        banners: [
            { title: '免费领取旅游景点团购价格', btn: '登录领取' },
            { title: '联系我们，报名跟团游', btn: '立即登录' },
        ],
    },
    'Study': {
        category: '留学申请',
    },
    'Fitment': {
        category: '家庭装修',
        banners: [
            { title: '近期装修节大促，报名参加', btn: '立即报名' },
            { title: '免费室内装修设计，电话咨询', btn: '立即报名' },
            { title: 'AI+设计师的最新设计，报名拿资料', btn: '立即报名' },
        ],
    },
    'Fitness': {
        category: '健身养生培训',
    },
    'jiashipeixun': {
        category: '驾驶培训',
    },
    'pifumeirong': {
        category: '皮肤美容',
    },
    'meitisuxing': {
        category: '美体塑形',
    },
    'yankemeirong': {
        category: '眼科美容',
    },
    'shilijiaozheng': {
        category: '视力矫正',
    },
};

const INTER = [{ space_src: 'baidujs', space_type: 'inters', space_id: 'u6973930', space_js: '//h5sfpb1.mangols.cn/production/pk-js/source/lc/common/s-cq.js' }];
const BANNER = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6973932', space_js: '//h5sfpb1.mangols.cn/source/r_m_lu/common/neu/eb.js' }];
const NATIVE_1 = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6973931', space_js: '//h5sfpb1.mangols.cn/source/qlk/openjs/t/static/m/source/dtd/u.js' }];
const NATIVE_2 = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6973933 ', space_js: '//h5sfpb1.mangols.cn/common/s/production/nmv/resource/o/static/fvf/f.js' }];
const NATIVE_3 = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6973934', space_js: '//h5sfpb1.mangols.cn/site/t/production/o_nwp/source/gwgk.js' }];

/** 广告位配置 */
export const SPACE_MAP = {
    GuideNative: NATIVE_1,
    GuideBanner: BANNER,
    HomeInter: INTER,
    HomeBanner: BANNER,
    HomeNative: NATIVE_1,
    HomeNativeCenter: NATIVE_2,
    HomeNativeBottom: NATIVE_3,
    HotInter: INTER,
    HotBanner: BANNER,
    HotNative: NATIVE_1,
    DetailInter: INTER,
    DetailBanner: BANNER,
    DetailNative: NATIVE_1,
};
