<template>
    <div class="home-view">
        <div class="home-title">人生大百科</div>
        <div class="tab-bar">
            <div
                v-for="(item, index) in tab"
                class="tab-bar-item"
                :class="activeIndex == index && 'active'"
                @click="onTabClick(item, index)"
            >
                <span>{{ item.title }}</span>
            </div>
        </div>
        <!-- <div class="sub-tab-bar">
            <div
                v-for="(item, index) in tab[activeIndex].children"
                class="sub-tab-bar-item"
                :class="subActiveIndex === index && 'active'"
                @click="onSubTabClick(item, index)"
            >
                <span>{{ item.title }}</span>
            </div>
        </div> -->
        <div class="tab-content" v-slide>
            <RouterView/>
        </div>
    </div>
</template>

<script setup lang="ts">
import { Haa, OeReportType, OeReportSubType } from "@haa/fwk-h5/lib/index";
// sdk监听生命周期
Haa.util().useLifeCycle();

import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
const route = useRoute();
// const tab = ref<TabItemType[]>([
//     {
//         title: '医美',
//         path: '/cosmetic',
//         key: 'costemic',
//         children: [
//             {
//                 title: '牙齿治疗',
//                 path: '/teeth',
//                 key: 'teeth',
//             },
//             {
//                 title: '美体塑性',
//                 path: '/bodycare',
//                 key: 'bodycare',
//             },
//             {
//                 title: '皮肤美容',
//                 path: '/skin',
//                 key: 'skin',
//             },
//             {
//                 title: '眼科美容',
//                 path: '/eye',
//                 key: 'eye',
//             },
//             {
//                 title: '视力检查',
//                 path: '/eyesight',
//                 key: 'eyesight',
//             },
//         ],
//     },
//     {
//         title: '教育',
//         path: '/education',
//         key: 'hot',
//         children: [
//             {
//                 title: '驾驶培训',
//                 path: '/drive',
//                 key: 'drive',
//             },
//             {
//                 title: '英语培训',
//                 path: '/english',
//                 key: 'english',
//             },
//             {
//                 title: '留学申请',
//                 path: '/study',
//                 key: 'study',
//             },
//             {
//                 title: '健身养生培训',
//                 path: '/fitness',
//                 key: 'fitness',
//             }
//         ],
//     },
//     {
//         title: '房产',
//         path: '/house',
//         key: 'house',
//         children: [
//             {
//                 title: '家庭装修',
//                 path: '/fitment',
//                 key: 'fitment',
//             }
//         ]
//     },
// ]);
const tab = ref<TabItemType[]>([
    {
        title: '养生知识',
        path: '/wellness',
        key: 'wellness',
    },
    {
        title: '牙齿治疗',
        path: '/teeth',
        key: 'teeth',
    },
    {
        title: '视力检查',
        path: '/eyesight',
        key: 'eyesight',
    },
    // {
    //     title: '家庭装修',
    //     path: '/fitment',
    //     key: 'fitment',
    // },
    // {
    //     title: '头发种植',
    //     path: '/hair',
    //     key: 'hair',
    // },
    // {
    //     title: '英语培训',
    //     path: '/english',
    //     key: 'english',
    // },
]);
const router = useRouter();
// const onTabClick = (item: TabItemType, index: number) => {
//     activeIndex.value = index;
//     subActiveIndex.value = 0;
//     router.push(item.path);
// }
// const onSubTabClick = (item: TabItemType, index: number) => {
//     subActiveIndex.value = index;
//     router.push(item.path);
// }
// const activeIndex = ref(tab.value.findIndex(item => item.children?.some(sub => sub.path === route.path)));
// const subActiveIndex = ref(tab.value[activeIndex.value].children?.findIndex(item => item.path === route.path));

const onTabClick = (item: TabItemType, index: number) => {
    activeIndex.value = index;
    router.push(item.path);
}
const activeIndex = ref(tab.value.findIndex(item => item.path === route.path));
// const subActiveIndex = ref(tab.value[activeIndex.value].children?.findIndex(item => item.path === route.path));
</script>

<style scoped lang="less">
.home-view {
    height: 100vh;
    background-image: url('@/assets/images/common/home_bg.png');
    background-size: 100% 100%;
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    .home-title {
        font-size: 35px;
        line-height: 52px;
        color: #fff;
        font-weight: 700;
        text-align: center;
    }
    .tab-bar {
        margin-top: 60px;
        display: flex;
        justify-content: center;
        .tab-bar-item {
            display: flex;
            align-items: center;
            margin: 0 30px;
            border-bottom: 6px solid transparent;
            span {
                font-size: 34px;
                line-height: 52px;
                color: #AFAFAF;
            }
            &.active {
                border-bottom-color: #70B4FF;
                span {
                    color: #2B9AFF;
                    font-weight: 700;
                }
            }
        }
    }
    .sub-tab-bar {
        margin: 30px 0;
        display: flex;
        justify-content: center;
        .sub-tab-bar-item {
            display: flex;
            align-items: center;
            margin: 0 20px;
            border-bottom: 6px solid transparent;
            span {
                font-size: 26px;
                line-height: 42px;
                color: #AFAFAF;
            }
            &.active {
                border-bottom-color: #70B4FF;
                span {
                    color: #2B9AFF;
                    font-weight: 700;
                }
            }
        }
    }
    .tab-content {
        flex: 1;
        overflow-y: auto;
    }
}
</style>
