import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
// import GuideView from "../views/GuideView.vue";
import GuideViewNew from "../views/GuideViewNew.vue";
import HomeView from "../views/HomeView.vue";
import { Haa, OeReportType, OeReportSubType } from '@haa/fwk-h5/lib/index';
const { oeReport } = Haa.util().useOeReport();

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Guide',
        component: GuideViewNew,
        meta: {
            spaceKeys: ['GuideNative', 'GuideBanner'],
        },
    },
    {
        path: "/cosmetic",
        name: "Costemic",
        component: HomeView,
        redirect: '/wellness',
        children: [
            {
                path: '/teeth',
                name: 'Teeth',
                component: () => import('../views/home/home.vue'),
                meta: {
                    spaceKeys: ['HomeInter', 'HomeBanner', 'HomeNative', 'HomeNativeCenter', 'HomeNativeBottom'],
                },
            },
            {
                path: '/eyesight',
                name: 'Eyesight',
                component: () => import('../views/home/home.vue'),
                meta: {
                    spaceKeys: ['HomeInter', 'HomeBanner', 'HomeNative', 'HomeNativeCenter', 'HomeNativeBottom'],
                },
            },
            {
                path: '/wellness',
                name: 'Wellness',
                component: () => import('../views/home/home.vue'),
                meta: {
                    spaceKeys: ['HomeInter', 'HomeBanner', 'HomeNative', 'HomeNativeCenter', 'HomeNativeBottom'],
                },
            },
        ],
    },
    {
        path: '/detail',
        name: 'Detail',
        component: () => import('../views/DetailView.vue'),
        meta: {
            spaceKeys: ['DetailInter', 'DetailBanner', 'DetailNative'],
        },
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    const { useParams } = require('@/store/useParams');
    const { params } = useParams();
    if (to.path == '/' && params.skip == 1) {
        oeReport(OeReportType.Action, OeReportSubType.BtnClick, 'Guide', 'skip=1直接上报');
        next('/wellness');
    } else {
        next();
    }
})

export default router;
